import type { GlobalCurrency } from '~~/types/currency.types'

// This function will be in type error if not all the global currencies are provided to it
const arrayOfAllGlobalCurrency = <T extends GlobalCurrency[]>(
  array: T & ([GlobalCurrency] extends [T[number]] ? unknown : 'Invalid')
) => array;

// Array of all the global currency codes supported by the app
const allCurrencyCodes = arrayOfAllGlobalCurrency(['USD', 'EUR', 'CHF', 'CAD'])

// Base currency code of the app (every amounts will be at least converted to this currency)
// * This is also the base currency code of the conversion rates in the database, so it cannot be change without being careful
const baseCurrencyCode: GlobalCurrency = 'USD'

// Currency code of the user (every amounts can also be converted to this currency)
const defaultUserCurrencyCode: GlobalCurrency = 'EUR'
const userCurrencyCode = ref<GlobalCurrency>(defaultUserCurrencyCode)

// Current global currency code used by the app (the currency code to show the amounts in)
const globalCurrencyCode = ref<GlobalCurrency>(userCurrencyCode.value)

// State to know if the user currency code must be used globally
const isUserCurrencyCodeGlobal = ref<boolean>(true)
watch(isUserCurrencyCodeGlobal, () => {
  if (isUserCurrencyCodeGlobal.value) {
    globalCurrencyCode.value = userCurrencyCode.value
  } else {
    globalCurrencyCode.value = baseCurrencyCode
  }
})
watch([globalCurrencyCode, userCurrencyCode], () => {
  // Necessary because we can change the user currency code in the settings page but the layout doesn't update on page change
  isUserCurrencyCodeGlobal.value = globalCurrencyCode.value === userCurrencyCode.value
})

// Function to check if a currency code is a currency code of the app
const isGlobalCurrencyCode = (currencyCode: string): currencyCode is GlobalCurrency => {
  return allCurrencyCodes.includes(currencyCode as GlobalCurrency)
}

export function useCurrencyCodes() {
  // Loading the saved value from cookie (and updating it when necessary)
  useCookieSaving<GlobalCurrency>('globalCurrencyCode', globalCurrencyCode)
  useCookieSaving<GlobalCurrency>('userCurrencyCode', userCurrencyCode)

  // Refreshing the state according to the saved values
  isUserCurrencyCodeGlobal.value = userCurrencyCode.value === globalCurrencyCode.value

  return {
    allCurrencyCodes,
    baseCurrencyCode,
    userCurrencyCode,
    globalCurrencyCode,
    isUserCurrencyCodeGlobal,
    isGlobalCurrencyCode
  }
}